import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import ContentHeroNew from '../components/content-hero-new'
import Contact from '../components/contact'
import Button from '../components/button'
import Container from '../components/container'
import Subheadline from '../components/content-subheadline'

import { changelogItemContainer } from './changelog.module.scss'

const ChangeLogItem = props => {
  return (
    <div className={changelogItemContainer}>
      <div className="version-date">
        <span className="date">{props.date}</span>
        {/* <span className="version">{props.version}</span> */}
      </div>
      <div className="content">
        {props.newFeatures && (
          <div className="item">
            <span className="subheadline">
              <span className="icon" role="img" aria-label="New features & improvements">
                🎉
              </span>
              New features & improvements
            </span>
            {props.newFeatures}
          </div>
        )}
        {props.integrations && (
          <div className="item">
            <span className="subheadline">
              <span className="icon" role="img" aria-label="integration">
                🔌
              </span>
              Integrations
            </span>
            {props.integrations}
          </div>
        )}
        {props.bugfixes && (
          <div className="item">
            <span className="subheadline">
              <span className="icon" role="img" aria-label="Bug-Fixes">
                🛠
              </span>
              Bug-Fixes
            </span>
            {props.bugfixes}
          </div>
        )}
      </div>
    </div>
  )
}

const IndexPage = ({ data }) => (
  <React.Fragment>
    <SEO title="Changelog" />

    {/* <ContentHeroNew headline="Changelog" description="Download the Basaas App for Mac OS or Windows" /> */}

    <Container>
      <ChangeLogItem
        // version="Basaas 2.2.0"
        date="27. Apr 2022"
        newFeatures={
          <ul>
            <li>
              <span className="badge workplace">Workplace</span> Assign all groups with a single click when creating a new user
            </li>
            <li>
              <span className="badge workplace">Workplace</span> Basaas Partner can now use a custom URL for their clients to register
            </li>
          </ul>
        }
        // bugfixes={
        //   <ul>
        //     <li>
        //       <span className="badge workplace">Workplace</span> Dashboard: apps widgets cannot be created
        //     </li>
        //     <li>
        //       <span className="badge workplace">Workplace</span> Group Management: show correct number of users in user tab
        //     </li>
        //   </ul>
        // }
        // integrations={
        //   <ul>
        //     <li>
        //       <span className="badge workplace">Workplace</span> Introducing <strong>Onboarding Tasks</strong>: create a set of tasks for new users
        //     </li>
        //   </ul>
        // }
      />
    </Container>

    <Container withBackground>
      <ChangeLogItem
        // version="Basaas 2.2.0"
        date="10. Dec 2021"
        newFeatures={
          <ul>
            <li>
              <span className="badge workplace">Workplace</span> New <strong>Support Widget</strong>: get access to the help center from anywhere
            </li>
            <li>
              <span className="badge workplace">Workplace</span> User management: add explaination for admins in case they cannot delete other admins
            </li>
            <li>
              <span className="badge workplace">Workplace</span> Corporate Widgets now have an indicator in the widget lists
            </li>
          </ul>
        }
        bugfixes={
          <ul>
            <li>
              <span className="badge workplace">Workplace</span> User edit: group selection can now have multiple lines
            </li>
            <li>
              <span className="badge workplace">Workplace</span> Dashboard: apps widgets cannot be created
            </li>
            <li>
              <span className="badge workplace">Workplace</span> Group Management: show correct number of users in user tab
            </li>
          </ul>
        }
        // integrations={
        //   <ul>
        //     <li>
        //       <span className="badge workplace">Workplace</span> Introducing <strong>Onboarding Tasks</strong>: create a set of tasks for new users
        //     </li>
        //   </ul>
        // }
      />

      <ChangeLogItem
        // version="Basaas 2.2.0"
        date="02. Nov 2021"
        newFeatures={
          <ul>
            <li>
              <span className="badge workplace">Workplace</span> Introducing <strong>Workplace Templates</strong>: create a default dashboard for new users
            </li>
            <li>
              <span className="badge workplace">Workplace</span> Introducing <strong>Onboarding Tasks</strong>: create a set of tasks for new users
            </li>
            <li>
              <span className="badge workplace">Workplace</span> Assign groups to new users directly in the creation dialog
            </li>
            <li>
              <span className="badge workplace">Workplace</span> New 404 page with links to most used pages
            </li>
            <li>
              <span className="badge workplace">Workplace</span> Add pagination to lists (e.g. groups, users, apps)
            </li>
            <li>
              <span className="badge extension">Browser Extension</span> Add app button to extension sidebar on every page
            </li>
            <li>
              <span className="badge extension">Browser Extension</span> Password Manager: improvements and bug fixes
            </li>
          </ul>
        }
        // bugfixes={
        //   <ul>
        //     <li>xxxxx</li>
        //   </ul>
        // }
        // integrations={
        //   <ul>
        //     <li>xxxx</li>
        //   </ul>
        // }
      />

      <ChangeLogItem
        // version="Basaas 2.2.0"
        date="29. Sep 2021"
        newFeatures={
          <ul>
            <li>
              Chrome Extension: Added the <strong>Password Manager (BETA)</strong>
            </li>
            <li>
              Tasks: Show <strong>complete project tasks</strong>
            </li>
            <li>
              Tasks: <strong>Filter tasks in a project by user</strong> by clicking on the avatar
            </li>
            <li>Workplace: Tasks Widget: reworked add Tasks modal </li>
            <li>Workplace: Add extension download link to support Widget</li>
            <li>Chrome Extension: Add onboarding step to pin the extension in the browser</li>
          </ul>
        }
        bugfixes={
          <ul>
            <li>Workplace: Fixed tasks widget displaying wrong tasks</li>
            <li>Tasks: Optimized the drag and drop behavior</li>
            <li>Tasks: New tasks in a project are not automatically focused tasks</li>
            <li>Tasks: Fixed a bug that prevents the user of creating Google tasks</li>
            <li>Chrome Extension: Fixed the login redirect in the New Tab</li>
            <li>Basaas App: Fixed the login behavior in multiple apps (e.g. Slack, HubSpot)</li>
          </ul>
        }
      />

      <ChangeLogItem
        version="Basaas 2.1.0"
        date="21. Jul 2021"
        newFeatures={
          <ul>
            <li>
              Basaas Workplace: Add <strong>My Day view in Workplace</strong> – all your tasks and events of the day in one place
            </li>
            <li>Chrome Extension: Tooltips added for your apps in the sidebar</li>
            <li>Chrome Extension: Added a zero state to the calendar view to make the setup as easy as possible</li>
            <li>Chrome Extension: Added Quick-Launch as an option to the Basaas Extension to start all your sidebar apps at once in a separate window</li>
            <li>Chrome Extension: Added german translation</li>
          </ul>
        }
        bugfixes={
          <ul>
            <li>Basaas Tasks: removed delete project option if you are not having the permissions to delete it</li>
          </ul>
        }
      />
      <ChangeLogItem
        version="Basaas 2.0.0"
        date="17. May 2021"
        newFeatures={
          <ul>
            <li>
              Web: <strong>New navigation</strong>, switch between your Workplace and Tasks and open Administration or the Integration manager everywhere in Basaas
            </li>
            <li>
              Web: <strong>Basaas Tasks is now available</strong>. Manage and complete your tasks from your integrated apps
            </li>
            <li>Web: Create Tasks in Basaas Tasks and assign them to colleagues</li>
            <li>Web: Focus Tasks to select the most important tasks</li>
            <li>
              Web: Add <strong>Projects in Basaas Tasks</strong> to share tasks related to milestones or topics with your team
            </li>
            <li>Integration Manager: Manage the integrated apps for Basaas Tasks and the global search</li>
            <li>Improved Global Search behavior</li>
          </ul>
        }
        bugfixes={
          <ul>
            <li>ClickUp: Fix a bug preventing to connect to your ClickUp account</li>
            <li>Microsoft To-Do: Showing more than ten tasks in the task widget</li>
          </ul>
        }
      />

      <ChangeLogItem
        version="Basaas 1.17.2"
        date="18. Feb 2021"
        bugfixes={
          <ul>
            <li>Fixed: Global Search: a bug preventing the search to get data from your integrated apps</li>
            <li>Fixed minor bugs with the Edit Tasks functionality in the widgets</li>
          </ul>
        }
      />

      <ChangeLogItem
        version="Basaas 1.16.6"
        date="10. Dec 2020"
        newFeatures={
          <ul>
            <li>New admin setting: disable Basaas App updates for managed device environments</li>
            <li>Basaas App: you can now disable the spell checker in the settings</li>
            <li>Redesigned footer in the web workplace</li>
            <li>Redesign account service pages (e.g. reset password)</li>
          </ul>
        }
        bugfixes={
          <ul>
            <li>Navigation bar: Fix the disabled forward / backward arrows in the navigation bar</li>
          </ul>
        }
      />

      <ChangeLogItem
        version="Basaas 1.16.3"
        date="1. Dec 2020"
        newFeatures={
          <ul>
            <li>Password manager is now available to all users and contains further fixes (e.g. duplicates)</li>
            <li>Edit your tasks: You can now edit existing tasks via Tasks widget</li>
            <li>New user now get a few onboarding tasks to get to know Basaas</li>
            <li>Now you got inline tooltips giving you some more information in place</li>
            <li>Basaas App: added general spellchecker</li>
            <li>Basaas App: tasks created in apps using the context menu now do not have a pre-filled name anymore</li>
          </ul>
        }
        bugfixes={
          <ul>
            <li>Basaas App – Windows: Fix error with task bar badge icon resulting in a crash</li>
            <li>Task widget: refresh sometimes results in duplicate entries</li>
          </ul>
        }
        integrations={
          <ul>
            <li>Microsoft Teams: Added screen sharing support</li>
            <li>Slack: Fix notifications and improved general behaviour</li>
          </ul>
        }
      />
    </Container>
  </React.Fragment>
)

export default IndexPage

// extracted by mini-css-extract-plugin
export const changelogItemContainer = "changelog-module__changelogItemContainer--u-tKU";
export const versionDate = "version-date";
export const content = "content";
export const version = "version";
export const date = "date";
export const item = "item";
export const subheadline = "subheadline";
export const icon = "icon";
export const badge = "badge";
export const workplace = "workplace";
export const tasks = "tasks";
export const extension = "extension";